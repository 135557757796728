<template>
  <div class="bg-white w-full p-3">
    <section class="h-full w-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.queues')"
        :showButton="false"
      />

      <!-- Domains table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="queues"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          v-if="queues && queues?.length"
          :paginator="true"
          :rows="10"
          removableSort
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          paginatorPosition="top"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} queues"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          selectionMode="single"
          :rowClass="rowClass"
          @rowSelect="onRowSelect"
          :globalFilterFields="['name']"
        >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>
              <div class="flex gap-2 items-center ml-auto">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                </IconField>
              </div>
            </div>
          </template>
          <Column
            sortable
            field="name"
            style="width: 15%"
            :header="$t('xpbx.table-field.name')"
          >
            <template #body="{ data }">
              <router-link :to="`/xpbx/settings/queue/${data?.id}`">{{
                data?.name
              }}</router-link>
            </template>
          </Column>
          <Column
            sortable
            field="queue_number"
            style="width: 15%"
            :header="$t('xpbx.table-field.queue_number')"
          >
            <template #body="{ data }">
              {{ data?.queue_number }}
            </template>
          </Column>
          <Column
            sortable
            field="weight"
            style="width: 15%"
            :header="$t('xpbx.table-field.weight')"
          >
            <template #body="{ data }">
              {{ data?.weight }}
            </template>
          </Column>
          <Column
            sortable
            field="wrapuptime"
            style="width: 15%"
            :header="$t('xpbx.table-field.wrapuptime')"
          >
            <template #body="{ data }">
              {{ data?.wrapuptime }}
            </template>
          </Column>
          <Column
            sortable
            field="timeout"
            style="width: 15%"
            :header="$t('xpbx.table-field.timeout')"
          >
            <template #body="{ data }">
              {{ data?.timeout }}
            </template>
          </Column>
          <Column
            sortable
            field="strategy"
            style="width: 15%"
            :header="$t('xpbx.table-field.strategy')"
          >
            <template #body="{ data }">
              {{ data?.strategy }}
            </template>
          </Column>
          <Column
            sortable
            field="unblock"
            style="width: 5%"
            :header="$t('xpbx.table-field.unblock')"
          >
            <template #body="{ data }">
              {{ data?.unblock }}
            </template>
          </Column>

          <Column
            sortable
            field="create_time"
            filterField="create_time"
            dataType="date"
            style="width: 15%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.create_time" />
            </template>
          </Column>
          <Column
            sortable
            dataType="date"
            field="update_time"
            filterField="update_time"
            style="width: 15%"
            :header="$t('xpbx.table-field.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.update_time" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="selectedRecord?.name">
            {{
              $t("notify.xpbx.message.confirm_delete", {
                delete: `domain ${selectedRecord?.name}`,
              })
            }}</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            text
            @click="deleteRecordsDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            text
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="queueDialog"
        :style="{ width: '700px' }"
        :header="$t('xpbx.pages.heading.create_queue')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!--Name -->
        <div class="field">
          <label for="company">{{ $t("xpbx.labels.name") }}</label>
          <InputText
            id="name"
            v-model.trim="form.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !form.name }"
          />
          <small class="p-error" v-if="!!errors?.name?.[0]">{{
            errors?.name?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.name')" />

        <!--Queue number -->
        <div class="field">
          <label for="company">{{
            $t("xpbx.placeholders.queue_number")
          }}</label>
          <InputText
            id="queue_number"
            v-model.trim="form.queue_number"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !form.queue_number }"
          />
          <small class="p-error" v-if="!!errors?.queue_number?.[0]">{{
            errors?.queue_number?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.number')" />

        <div class="flex items-center justify-between gap-4">
          <!-- Unblock -->
          <div class="flex-1">
            <div class="field flex justify-start items-center gap-3">
              <label class="text-left" for="is_active">{{
                $t("xpbx.labels.unblock")
              }}</label>
              <InputSwitch v-model="form.unblock" />
            </div>
            <QueueHint :title="$t('xpbx.hints.queue-general.unblock')" />
          </div>

          <div class="flex-1">
            <!-- Connect again now -->
            <div class="field flex justify-start items-center gap-3">
              <label class="text-left" for="is_active">{{
                $t("xpbx.labels.connect_agent_now")
              }}</label>
              <InputSwitch v-model="form.connect_agent_now" />
            </div>
            <QueueHint :title="$t('xpbx.hints.queue-general.connect_again')" />
          </div>
        </div>

        <!--Wrapuptime -->
        <div class="field">
          <label for="company">{{ $t("xpbx.placeholders.wrapuptime") }}</label>
          <div class="w-20">
            <form-select
              v-model="form.wrapuptime"
              :options="wrapuptimeOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.wrapuptime')" />

        <!--Weight -->
        <div class="field">
          <label for="company">{{ $t("xpbx.placeholders.weight") }}</label>
          <div class="w-20">
            <form-select
              v-model="form.weight"
              :options="weightOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.weight')" />

        <!--Agent period -->
        <div class="field">
          <label for="company">{{ $t("xpbx.placeholders.period") }}</label>
          <div class="w-20">
            <form-select
              v-model="form.find_agent_period"
              :options="agentPeriodOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.agent_period')" />

        <!--Timeout -->
        <div class="field">
          <label for="company">{{ $t("xpbx.placeholders.timeout") }}</label>
          <div class="w-20">
            <form-select
              v-model="form.timeout"
              :options="timeoutOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.timeout')" />

        <!--Strategy -->
        <div class="field">
          <label for="company">{{ $t("xpbx.placeholders.strategy") }}</label>
          <div class="w-40">
            <form-select
              v-model="form.strategy"
              :options="strategyOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.strategy')" />

        <!--Is active -->
        <div class="field">
          <label for="company">{{ $t("xpbx.labels.is_active") }}</label>
          <div class="w-40">
            <form-select
              v-model="form.is_active"
              :options="activeOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.queue-general.is_active')" />

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            text
            @click="queueDialog = false"
          />
          <Button label="Save" icon="pi pi-check" text @click="create" />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, onMounted, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
import { validateCreateQueue } from "@/composables/auth/createDomainValidations";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputSwitch from "primevue/inputswitch";
import InputIcon from "primevue/inputicon";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";

export default {
  name: "Queues",

  components: {
    PageHeader,
    DateItem,
    DataTable,
    Column,
    Loader,
    Button,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    QueueHint,
    InputSwitch,
  },

  setup() {
    const store = useStore();
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const form = ref({
      name: "",
      wrapuptime: 0,
      queue_number: "",
      strategy: "ringall",
      weight: 0,
      timeout: 30,
      is_active: 0,
      find_agent_period: 0,
      unblock: false,
      connect_agent_now: false,
    });
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const queueDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const countries = computed(() => store.getters.countries);
    const { queues, queue, findQueues, createQueue, deleteQueue } = useQueues();
    const {
      activeOptions,
      weightOptions,
      timeoutOptions,
      wrapuptimeOptions,
      agentPeriodOptions,
      strategyOptions,
    } = useQueueOptions();

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    // Data table functions
    const openNew = () => {
      form.value = {
        name: "",
        wrapuptime: 0,
        queue_number: "",
        strategy: "ringall",
        weight: 0,
        timeout: 30,
        is_active: 0,
        find_agent_period: 0,
        unblock: false,
        connect_agent_now: false,
      };
      isEdit.value = false;
      queueDialog.value = true;
    };

    const closeDialog = (value) => {
      queueDialog.value = value;
    };

    const reset = () => {
      form.value = {
        name: "",
        wrapuptime: 0,
        queue_number: "",
        strategy: "ringall",
        weight: 0,
        timeout: 30,
        is_active: 0,
        find_agent_period: 0,
        unblock: false,
        connect_agent_now: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const create = async () => {
      const isValid = validateCreateQueue(form.value);

      if (isValid) {
        const formData = {
          ...form.value,
          unblock: form.value.unblock ? 1 : 0,
          connect_agent_now: form.value.connect_agent_now ? 1 : 0,
        };

        await createQueue(formData);

        isEdit.value = false;
        queueDialog.value = false;
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteQueue(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": true === true,
          "table-active": isEdit.value && record?.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(queueDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
      await findQueues();
    });

    return {
      dt,
      form,
      isEdit,
      errors,
      queue,
      queues,
      queueDialog,
      weightOptions,
      selectedRecord,
      countryOptions,
      selectedRecords,
      deleteRecordsDialog,
      filters,
      submitted,
      scrollHeight,
      activeOptions,
      timeoutOptions,
      strategyOptions,
      wrapuptimeOptions,
      agentPeriodOptions,
      openNew,
      closeDialog,
      create,
      onRowSelect,
      rowClass,
      createQueue,
      deleteQueue,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.regex-example {
  color: #10b981;
  margin-top: 4px;
  display: inline-block;
}
</style>